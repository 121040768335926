.navbar {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 2.5rem;
    height: 50px;
    position: absolute;
    z-index: 2000;
    width: 100%;
}

.navbar--link {
    display: flex;
    list-style: none;
    vertical-align: middle;
    margin-left: auto;
}

.navbar--link-item {
    vertical-align: middle;
    height: 100%;
    padding: 0.8rem 2rem 0.5rem 2rem;
    margin: 0 0.1rem 0 0.1rem;
    align-items: center;
    cursor: pointer;
}

@media screen and (min-width:768px) {
    .navbar--link-item.active {
        height: 100%;
        width: 160px;
        background-size: 100% 200%;
        border-top: solid 4px #64D6DF;
        background-image: linear-gradient(to bottom, #1B2936 52%, #64D6DF 30%);
        -webkit-transition: background-position 0.1s;
        -moz-transition: background-position 0.1s;
        transition: background-position 0.1s;
    }
}

.box:hover {
    background-position: 0 -100%;
    color: white;
    border-top: 0;
}

li {
    text-transform: uppercase;
}

li a:link {
    margin-top: 8px;
    margin-left: 0px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-style: normal;
}

li a:visited {
    margin-top: 8px;
    margin-left: 0px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
}

li a:hover {
    margin-top: 8px;
    margin-left: 0px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-style: normal;
}

li a:active {
    margin-top: 8px;
    margin-left: 0px;
    color: #db6464;
    text-decoration: none;
    font-weight: bold;
    font-style: normal;
}

.navbar-sticky {
    background: #1B2936;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
}

.navbar--logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--language {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
    cursor: pointer;
}

.navbar--logo-holder {
    display: flex;
    cursor: pointer;
}

.navbar--hamburger {
    display: none;
}

@media screen and (max-width: 768px) {
    .navbar {
        display: block;
        align-items: center;
        padding: 0;
        height: 60px;
        position: relative;
        width: 100%;
    }
    .navbar--logo-holder {
        display: none;
    }
    .navbar--hamburger {
        display: flex;
        z-index:2;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        width: 30px;
        height: 30px;
    }
    ul {
        margin: 0;
    }
    .navbar--link {
        margin: 0;
        position: fixed;
        background: #000;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        clip-path: circle(100px at 95% -15%);
        -webkit-clip-path: circle(100px at 95% -15%);
        transition: all 1s ease-out;
        pointer-events: none;
    }
    .navbar--link.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
    }
}

h1 {
    color: #fff;
    font-family: 'Oswald', sans-serif;
}

@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }
    to {
        transform: translateY(0rem);
    }
}

@keyframes rotate {
    0% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(0rem);
    }
}