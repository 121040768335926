

.about {
    background: #05386B;
    flex-wrap: wrap;
}

.about-2 {
    background: #64D6DF;
}

.welcome--about {
    padding: 2rem;
    width: 50rem;
    color: #fff ;
    text-align: justify;
    margin: auto;
}

.link--logo{
    cursor: pointer;
    background:white;
    width: 30px;
    height: 30px;
    margin: 10px;
    padding: 3px;
    border-radius: 3px;
}

.break {
    flex-basis: 100%;
    height: 0;
  }