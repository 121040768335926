.welcome {
    background-color: #64D6DF;
    /* background-image: url("../../assets/images/home-background.jpg"); */
    /* background-attachment: fixed; */
    background-size: cover;
    line-height: 1.8;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

section .rectangle {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 10%;
    background:white;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    /* transform: rotate(-45deg) translate(30%,-300%); */
}
section .rectangle.rectangle1 {
    animation: animateRect 4s alternate infinite;
    transform: rotate(-45deg) translate(-30%,700%);
    opacity:0.5;
}
section .rectangle.rectangle2 {
    animation: animateRect 8s alternate infinite;
    opacity:0.8;
    transform: rotate(-45deg) translate(-30%,700%);
    animation-delay: -5s;
}
section .rectangle.rectangle3 {
    animation: animateRect 10s alternate infinite;
    transform: rotate(-45deg) translate(-30%,700%);
    animation-delay: -2s;
}

@keyframes animateRect {
    0%{
        transform: rotate(-45deg) translate(-30%,-700%);
    }
    100%{
        transform: rotate(-45deg) translate(-30%,-600%);
    }
}

section .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background:url("../../assets/images/wave.png");
    background-size: 1000px 100px;
}
section .wave.wave1 {
    animation: animate 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}

section .wave.wave2 {
    animation: animate2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}


section .wave.wave3 {
    animation: animate 30s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
}


section .wave.wave4 {
    animation: animate2 15s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
}

@keyframes animate {
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: 1000px;
    }
}


@keyframes animate2 {
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: -1000px;
    }
}

section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* scroll-snap-align: start center;
    scroll-snap-stop: always; */
}

main {
    /* scroll-snap-type: y mandatory; */
    /* overflow-y: scroll; */
    height: 100vh;
    
}

.welcome--logo {
    width: 20rem;
    border-radius: 10rem;
    margin: 20px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
}

.welcome__cta-primary {
    background: #e74c3c;
    color: #fff;
    margin-top: 1rem;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 20px;
    font-weight: 700;
}