.contact-section {
    background: steelblue;
    justify-content: flex-end;
    font-family: 'Oswald', sans-serif;
}

.contact-form {
    display: grid;
    height: 70%;
    min-height: 600px;
    width: 50%;
    padding: 5%;
    justify-content: center;
    align-items: center;
    background: steelblue;
    grid-template-columns: 70%;
}

.contact-image{
    width: 50%;
    background-color: white;
    height: 100%;
    display: block;
}

@media screen and (max-width:768px) {
    .contact-form {
        width: 100%;
    }
    .contact-image{
        display: none;
    }
}

.form-control {
    width: 100%;
    /* max-width: 900px; */
    margin: 20px 0;
}

.form-control label {
    display: block;
}

.form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 7px;
}

.form-control-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control-check label {
    flex: 1;
}

.form-control-check input {
    flex: 2;
    height: 20px;
}

.btn {
  display: inline-block;
  background: #5CDB95;
  color: #fff;
  border: none;
  width:200px;
  padding: 10px 20px;
  margin: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  justify-self: center;
}