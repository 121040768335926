@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  color: #fff;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}



a:link,a:visited,a:hover,a:active {
  margin-left: 0px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.95);

}

.btn-block {
  display: block;
  width: 100%;
}

.product {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.product.selected {
  border-left: 5px solid green;
}

.product h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  text-align: center;
  background-color: #9F94D7;
}



:root {
  --bg-color: #64D6DF;
  --typewriterSpeed: 3s;
  --typewriterCharacters: 15;
}

h2 {
  font-size: clamp(1rem, 2vw + 1rem, 4rem);
  position: relative;
  font-family: 'Oswald', sans-serif;
  position: relative;
  width: max-content;
}

h2::before,
h2::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

h2::before {
  background: var(--bg-color);
  animation: typewriter var(--typewriterSpeed)
    steps(var(--typewriterCharacters)) 1s forwards;
}

h2::after {
  width: 0.125em;
  background: white;
  animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s forwards,
    blink 750ms steps(var(--typewriterCharacters)) infinite;
}

.subtitle {
  color: white;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInUp 2s ease calc(var(--typewriterSpeed) + 2s) forwards;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
